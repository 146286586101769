export const WORDS = [
  'Vumpt',
  'Blonk',
  'Quimz',
  'Dralt',
  'Frinx',
  'Vopse',
  'Snolk',
  'Trivz',
  'Zompf',
  'Brulk',
  'Jaxle',
  'Plimd',
  'Quarn',
  'Sploz',
  'Fleng',
  'Vryth',
  'Plisk',
  'Droth',
  'Krimp',
  'Blivz',
  'Quixt',
  'Groft',
  'Quemb',
  'Trang',
  'Sprix',
  'Plond',
  'Vlump',
  'Grinx',
  'Flost',
  'Jalth',
  'Tralk',
  'Zifth',
  'Quorn',
  'Vlort',
  'Drint',
  'Flang',
  'Troft',
  'Blivk',
  'Gromp',
  'Plint',
  'Snive',
  'Brint',
  'Spalk',
  'Quist',
  'Trong',
  'Vroth',
  'Plang',
  'Snork',
  'Grint',
  'Frolk',
  'Zlomp',
  'Dlisk',
  'Bromp',
  'Jrink',
  'Dlomp',
  'Trisk',
  'Brolk',
  'Plimp',
  'Frint',
  'Quish',
  'Vrolt',
  'Skang',
  'Jrimp',
  'Bralk',
  'Zrilt',
  'Frong',
  'Splik',
  'Grolk',
  'Vlosh',
  'Splim',
  'Frask',
  'Blisk',
  'Vralk',
  'Sprol',
  'Quang',
  'Drong',
  'Flisp',
  'Trask',
  'Blomp',
  'Jralk',
  'Plong',
  'Flong',
  'Frand',
  'Fralk',
  'Quive',
  'Zralk',
  'Dralk',
  'Brilt',
  'Plomp',
  'Bilch',
  'Wumbo',
  'Chran',
  'Grimp',
  'Hoxen',
  'Snurl',
  'Mibex',
  'Slent',
  'Krong',
  'Zivox',
  'Dlarm',
  'Strox',
  'Klimy',
  'Nuvex',
  'Yibex',
  'Qwirk',
  'Plixo',
  'Vrink',
  'Cholp',
  'Plinx',
  'Wrent',
  'Clorp',
  'Gloth',
  'Vrilx',
  'Throk',
  'Vribe',
  'Klent',
  'Blixo',
  'Stolj',
  'Krunt',
  'Dwink',
  'Pluno',
  'Gunol',
  'Buipo',
  'Tunor',
  'Borpo',
  'Bipop',
]
